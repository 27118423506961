import {Container, Row, Col} from "react-bootstrap";
import logo from "../../assets/img/logo.svg";
import githubIcon from "../../assets/img/nav-github.svg";
import linkedinIcon from "../../assets/img/nav-linkedin.svg";
import "./Footer.css";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img className="footer-logo" src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a
                target="_blank"
                href="https://github.com/NaeMoney"
                rel="noreferrer"
              >
                <img src={githubIcon} alt="Icon" />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/in/connor-larkin-5839a42b7/"
                rel="noreferrer"
              >
                <img src={linkedinIcon} alt="Icon" />
              </a>
            </div>
          </Col>
        </Row>
        <p id="copyright">Copyright 2024. All Rights Reserved</p>
      </Container>
    </footer>
  );
};
