import "./Parent.css";
import video from "../../assets/vid/LoM.mp4";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img1 from "../../assets/img/lotm_1.png";
import img2 from "../../assets/img/lotm_2.png";
import img3 from "../../assets/img/lotm_3.png";
import img4 from "../../assets/img/lotm_4.png";
import img5 from "../../assets/img/lotm_5.png";
import img6 from "../../assets/img/lotm_6.png";

export const Parent = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: {max: 4000, min: 3000},
      items: 1,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 1,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 1,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 1,
    },
  };

  return (
    <div className="project">
      <div className="portal_banner">
        <div className="banner-text">
          <h1 id="project-title">{"Legend of the Museum"}</h1>
          <a href="#projects" className="portal-button">
            <span>Explore</span>
          </a>
        </div>
        <video id="vid-1" autoPlay muted loop>
          <source src={video} />
          This browser does not support the MP4 file
        </video>
      </div>

      <section id="projects">
        <h1 id="statement-1"> {"The World Needs Ewe!"}</h1>
        <div className="project-info">
          <p>
            <span className="info-bold">Genre:</span> 3D Top-Down RPG
          </p>
          <p>
            <span className="info-bold">Engine:</span> Unity
          </p>
          <p>
            <span className="info-bold">Game Length:</span> 15 Minutes
          </p>
        </div>
        <p id="paragraph-1">
          Dolly Saves the National Museum of Scotland is a charming 3D top-down
          RPG where you take control of Dolly the sheep, a beloved icon of
          science.
          <br />
          <br /> Explore the museum's grand halls, overcome quirky challenges,
          and face off against mischievous enemies! Help Dolly to solve puzzles,
          navigate exhibits, and outsmart mischievous enemies. <br />
          <br /> Developed for the National Museum of Scotland and as a
          university project, The Legend of the Museum offers a delightful and
          educational adventure for players of all ages.
        </p>
        <h2 className="lotm-h2">My Responsibilities</h2>
        <p id="responsibilities">
          ⦁ Create compelling and engaging levels that support the overall
          narrative and gameplay objectives.
          <br /> ⦁ Design environments with diverse challenges, puzzles, and
          secrets to explore.
          <br /> ⦁ Ensure smooth level progression and pacing, guiding players
          through the experience.
          <br /> ⦁ Collaborate with artists, programmers, and other designers to
          ensure level consistency and technical feasibility.
          <br /> ⦁ Develop the game's story concept, plot, and characters.
          <br /> ⦁ Craft engaging dialogue, quests, and environmental
          storytelling elements.
          <br /> ⦁ Maintain narrative consistency and tone throughout the game.
        </p>
        <h2 className="lotm-h2">
          All possible thanks to these talented people!
        </h2>
        <ul className="lotm-thanks-list">
          <li>Benjamin Lockhart (Programmer) </li>{" "}
          <li>Matthew Mulholland (Programmer) </li>{" "}
          <li>Raiyan Mehmood (Level Designer) </li>{" "}
          <li>Rachel Williams (Artist) </li>
          <li>Nicholas Campbell (Artist) </li>
          <li>Chloe Diffin (Artist) </li>
          <li>Ayna Gray (Artist)</li>
          <li>Sunil Rai (Artist)</li>
        </ul>

        <div className="img-container">
          <Carousel
            infinite={true}
            responsive={responsive}
            className="owl-carousel owl-theme skill-slider"
          >
            <div className="item">
              <img src={img1} alt="" />
            </div>
            <div className="item">
              <img src={img2} alt="" />
            </div>
            <div className="item">
              <img src={img3} alt="" />
            </div>
            <div className="item">
              <img src={img4} alt="" />
            </div>
            <div className="item">
              <img src={img5} alt="" />
            </div>
            <div className="item">
              <img src={img6} alt="" />
            </div>
          </Carousel>
        </div>
      </section>
    </div>
  );
};

export default Parent;
