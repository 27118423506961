import "./Parent.css";
import video from "../../assets/vid/Portal_2.mp4";
import img1 from "../../assets/img/Portal_2.png";
import img3 from "../../assets/img/portal_3.png";
import img4 from "../../assets/img/portal_4.png";
import img5 from "../../assets/img/portal_5.png";
import img6 from "../../assets/img/portal_6.png";
import img7 from "../../assets/img/portal_7.png";
import img8 from "../../assets/img/portal_8.png";
import img9 from "../../assets/img/portal_9.png";
import img10 from "../../assets/img/portal_10.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const Portal = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: {max: 4000, min: 3000},
      items: 1,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 1,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 1,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 1,
    },
  };

  return (
    <div className="project">
      <div className="portal_banner">
        <div className="banner-text">
          <h1 id="project-title">{"Portal 2"}</h1>
          <a href="#projects" className="portal-button">
            <span>Explore</span>
          </a>
        </div>
        <video id="vid-1" autoPlay muted loop>
          <source src={video} />
          This browser does not support the MP4 file
        </video>
      </div>

      <section id="projects">
        <h1 id="statement-1"> {"Logic with Lasers"}</h1>
        <div className="project-info">
          <p>
            {" "}
            <span className="info-bold">Genre:</span> First Person Puzzle Game
          </p>
          <p>
            <span className="info-bold">Engine:</span> Portal Chamber Creator
          </p>
          <p>
            <span className="info-bold">Game Length:</span> 20 Minutes
          </p>
        </div>
        <p id="paragraph-1">
          This chamber was created as a personal project and the first project
          designed in an ongoing goal to develop at least one level in each
          Valve title. The Puzzle showcases the flexibility of lasers in portal
          and how they can work with different tools in the game. The chamber is
          easy-medium in difficulty and should take around 5-10 minutes to
          complete.
        </p>
        <p id="paragraph-1">
          The aim was to create a simple, straightforward chamber based around
          lasers that takes advantage of a single laser in many different use
          cases to complete the chamber. This was done by creating 3 main
          sections of the chamber that is only accessible from using the laser,
          but making the user utilize the laser in different ways to access each
          section.
        </p>
        <p id="paragraph-1">
          After the first iteration of the level was completed, I wanted to make
          the chamber more scenic and overall look more fleshed out compared to
          what it currently looked like.
        </p>
        <h2 className="portal-paragraph-title">
          {" "}
          The environment was updated from looking like this:
        </h2>
        <img className="portal-3" alt="before-level" src={img3}></img>
        <h2 className="portal-paragraph-title">To looking like this:</h2>
        <img className="portal-4" alt="after-level" src={img4}></img>
        <p id="paragraph-1">
          After I was happy with how the chamber looked and felt, I began adding
          items just to guide the player if they are stuck in the puzzle. I did
          this by using lighting and certain cube types to try and push the
          player into the right direction if needed.
        </p>
        <p id="paragraph-1">
          In this section of thes puzzle, I utilized the warm lighting to
          signify where the player can place the portals that will result in a
          correct position for completing the section.
        </p>
        <img className="portal-5" alt="light-direction" src={img5}></img>
        <p id="paragraph-1">
          This alongside using different cube types to represent different use
          cases for each, will hopefully push the player in the right direction
          of solving the puzzle, without outright telling them how to do it.
        </p>
        <div className="img-container">
          <Carousel
            infinite={true}
            responsive={responsive}
            className="owl-carousel owl-theme skill-slider"
          >
            <div className="item">
              <img src={img1} alt="" />
            </div>
            <div className="item">
              <img src={img6} alt="" />
            </div>
            <div className="item">
              <img src={img7} alt="" />
            </div>
            <div className="item">
              <img src={img8} alt="" />
            </div>
            <div className="item">
              <img src={img9} alt="" />
            </div>
            <div className="item">
              <img src={img10} alt="" />
            </div>
          </Carousel>
        </div>
      </section>
    </div>
  );
};

export default Portal;
