export const ProjectCard = ({title, description, imgUrl, href, projButton}) => {
  return (
    <div className="proj-imgbx">
      <img id="img-blur" alt="blurred projects" src={imgUrl} />
      <div className="proj-txtx">
        <h4>{title}</h4>
        <span>{description}</span>
        <div>
          <a href={href} className={projButton}>
            <span>View</span>
          </a>
        </div>
      </div>
    </div>
  );
};
