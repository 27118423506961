import "./About.css";
import Nav from "react-bootstrap/Nav";
import {useState} from "react";
import GCU from "../../assets/img/GCU.jpg";
import MyStory from "../../assets/img/MyStory.jpg";
import headshot from "../../assets/img/anon.jpg";

export const About = () => {
  const [activeLink, setActiveLink] = useState("about");
  // const [scrolled, setScrolled] = useState(false);

  // useEffect(() => {
  //   const onScroll = () => {
  //     if (window.scrollY > 50) {
  //       setScrolled(true);
  //     } else {
  //       setScrolled(false);
  //     }
  //   };

  //   window.addEventListener("scroll", onScroll);

  //   return () => window.removeEventListener("scroll", onScroll);
  // }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  let paragraphContent = "";
  let paragraphPic = "";
  switch (activeLink) {
    case "about":
      paragraphContent = (
        <p className="about-paragraph">
          When I was just about 5 years old, I got my hands on my very first
          games console - the Playstation 2. From that moment on, I was
          absolutely hooked. I'd lose track of time diving into all sorts of
          games, exploring different genres until the early hours of the
          morning. I'd even challenge my grandad to rounds of GoldenEye on his
          old N64, though I never managed to beat him.
          <br />
          <br />
          These early experiences fuelled my passion for gaming, leading me to
          become curious about the intricate process behind creating these
          games. As I grew older, my interest shifted towards the design and
          theory aspects of game development, particularly in crafting levels
          and environments. Games like "Dark Souls 3" and "Elden Ring" by
          FromSoftware blew me away with their meticulously crafted worlds and
          breathtaking environments. Watching design conferences and diving into
          videos about various design elements opened my eyes to the depth and
          purpose behind every aspect of game design. It was fascinating to
          discover how every detail serves a specific function, contributing to
          the overall player experience.
          <br />
          <br />
          All of these experiences have inspired me to pursue my dream of
          becoming the best level designer I can be. I'm constantly seeking to
          expand my knowledge and skills in the craft, striving to create worlds
          that leave players as inspired and captivated as I have been. My
          journey as a level designer is just beginning, and I'm excited to see
          where it takes me.
        </p>
      );
      paragraphPic = (
        <img className="about-img" alt="connor-face" src={MyStory} />
      );

      break;
    case "experience":
      paragraphContent = (
        <div className="about-experience">
          <h1 className="experience-title">
            Self-Directed Learning in Game Development:{" "}
          </h1>
          <p className="about-paragraph">
            Over the past 5+ years, I've been deeply engaged in honing my skills
            in game development, particularly within Unity and Unreal Engine.
            Through a series of solo projects, I've explored various aspects of
            game design, from crafting immersive environments to developing
            gameplay mechanics. Each project has been an opportunity for growth,
            with every iteration pushing me to refine my craft and explore new
            techniques.{" "}
          </p>
          <h1 className="experience-title">
            Team Collaboration in "Legend of the Museum":
          </h1>
          <p className="about-paragraph">
            As part of a small, collaborative team, I had the privilege of
            contributing to the creation of "Legend of the Museum." In this
            project, I took on a leadership role in shaping the narrative
            elements of the game. Additionally, I played a key role in assisting
            with the design of levels and puzzles, leveraging my skills in
            environment creation and problem-solving to enhance the player
            experience. Working alongside talented individuals allowed me to not
            only share my expertise but also learn from the diverse perspectives
            and skills of my team members.
          </p>
        </div>
      );
      paragraphPic = <img className="about-img" alt="university" src={GCU} />;
      break;
    case "education":
      paragraphContent = (
        <div>
          <h2 className="education-h2">
            Honourable Bachelor of Arts (Honours) in Computer Games (Design)
            2024 | Glasgow Caledonian University{" "}
          </h2>
          <p className="education-paragraph">
            My passion for video games led me to pursue an Honours Bachelor of
            Arts in Computer Games (Design). This program has equipped me with
            the skills and knowledge to develop engaging and innovative games.{" "}
          </p>
          <h3 className="education-h3">
            Throughout my studies, I've gained a strong foundation in:
          </h3>
          <p className="education-bulleted-list">
            ⦁ Game Design Principles:  Developing core mechanics, crafting
            engaging user experiences, and understanding game theory.
            <br />
            <br />
            ⦁ Programming: Proficiency in languages like C# for game
            development. <br />
            <br />⦁ 3D Modeling & Animation:  Creating visually appealing game
            environments and characters. <br />
            <br /> ⦁ Game Art & Design:  Understanding visual storytelling, user
            interface design, and the overall aesthetic of games. <br />
            <br /> ⦁ Project Management and Team Collaboration:  Leading and
            collaborating on game development projects from ideation to
            completion.{" "}
          </p>{" "}
          <h2 className="education-h2">
            Higher National Certificate (HNC) in Fitness, Health & Exercise 2019
            | West College Scotland
          </h2>
          <p className="education-paragraph">
            Prior to studying game design, I established a strong foundation in
            health and wellness through a Higher National Certificate (HNC)
            program. This experience provided me with valuable knowledge:{" "}
          </p>{" "}
          <p className="education-bulleted-list">
            ⦁ Maintain a Healthy Lifestyle: I prioritize a healthy and balanced
            approach to life, which contributes to my focus, creativity, and
            overall well-being. This focus translates into a strong work ethic
            and dedication to any project I undertake. <br /> <br />⦁ Lifelong
            Learner: My studies in health and wellness fostered a strong
            foundation for continual learning and self-improvement. This
            translates into my approach to game design, where I actively seek
            new knowledge and challenges.{" "}
          </p>
          <p className="education-paragraph">
            By prioritizing my physical and mental well-being, I position myself
            for sustained success and ongoing learning. This foundation in
            health complements my passion for game design, making me a
            well-rounded and adaptable individual.
          </p>
        </div>
      );
      paragraphPic = null;

      break;
    default:
      paragraphContent = "Default paragraph content...";
      paragraphPic = (
        <img className="experience-img" alt="fallback" src={headshot} />
      );
  }

  return (
    <div>
      <h1 id="title">{"About Me"}</h1>
      <div className="nav-buttons">
        <Nav className="mx-auto">
          <Nav.Link
            className={
              activeLink === "about" ? "active navbar-link" : "navbar-link"
            }
            onClick={() => onUpdateActiveLink("about")}
          >
            My Story
          </Nav.Link>
          <Nav.Link
            className={
              activeLink === "experience" ? "active navbar-link" : "navbar-link"
            }
            onClick={() => onUpdateActiveLink("experience")}
          >
            Experience
          </Nav.Link>
          <Nav.Link
            className={
              activeLink === "education" ? "active navbar-link" : "navbar-link"
            }
            onClick={() => onUpdateActiveLink("education")}
          >
            Education
          </Nav.Link>
        </Nav>
      </div>
      <div className="about">
        <span
          className={
            activeLink === "education" ? "education-img" : "about-img-container"
          }
        >
          {paragraphPic}
        </span>
        <span
          className={
            activeLink === "education" ? "education-container" : "about-content"
          }
        >
          {paragraphContent}
        </span>
      </div>
    </div>
  );
};

export default About;
