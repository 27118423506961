import "./Parent.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import video from "../../assets/vid/Pharm.mp4";
import pharm_1 from "../../assets/img/pharm_1.png";
import pharm_2 from "../../assets/img/pharm_2.png";
import pharm_3 from "../../assets/img/pharm_3.png";
import pharm_4 from "../../assets/img/pharm_4.png";
import pharm_5 from "../../assets/img/pharm_5.png";
import pharm_6 from "../../assets/img/pharm_6.png";
import pharm_7 from "../../assets/img/pharm_7.png";
import pharm_8 from "../../assets/img/pharm_8.png";

export const Pharm = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: {max: 4000, min: 3000},
      items: 1,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 1,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 1,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 1,
    },
  };

  return (
    <div className="project">
      <div className="portal_banner">
        <div className="banner-text">
          <h1 id="project-title">{"Pharmystery"}</h1>
          <a href="#projects" className="portal-button">
            <span>Explore</span>
          </a>
        </div>
        <video id="vid-1" autoPlay muted loop>
          <source src={video} />
          This browser does not support the MP4 file
        </video>
      </div>

      <section id="projects">
        <h1 id="statement-1"> {"Prepare for a Scare"}</h1>
        <div className="project-info">
          <p>
            <span className="info-bold">Genre:</span> First Person Horror Game
          </p>
          <p>
            <span className="info-bold">Engine:</span> Unity
          </p>
          <p>
            <span className="info-bold">Game Length:</span> 15 Minutes
          </p>
        </div>
        <p id="paragraph-1">
          Pharmystery is a first-person psychological horror exploration that
          plunges you into the darkest secrets of a long-abandoned pharmacy. As
          you navigate through the desolate pharmacy, you begin to find more
          than you bargained for. Uncover the forgotten story of the pharmacy
          and escape with your life.
        </p>
        <p id="paragraph-1">
          This Project was developed as a personal solo project that was
          developed over an 8 week period and really pushed me to work under
          time pressure while also trying to maintain a standard of product that
          is still visually appealing and enjoyable.
        </p>
        <h2 className="pharm-h2">Environment</h2>
        <p id="paragraph-1">
          The level takes place within a long-abandoned pharmacy that is in the
          middle of a neglected town that has been filled with crime and waste.
          The level takes the player through the worn-down pharmacy that has
          been destroyed from the inside out. The player will come upon secret
          rooms that are hidden deep within the pharmacy. These rooms will be
          asylum, testing rooms that have been used for secret testing that no
          one knew about.
        </p>
        <h2 className="pharm-h2">Lighting</h2>
        <p id="paragraph-1">
          The level will take place inside and during night-time so the only
          source of lighting that the player has is old, inconsistent lighting
          that was inside the pharmacy and testing labs way before it was
          abandoned. Due to this, the environment will be dark and gloomy with
          flickering lights and dysfunctional lights around the level.
        </p>
        <h2 className="pharm-h2">Pacing</h2>
        <p id="paragraph-1">
          The pacing of the game should be typically the same throughout if the
          player is taking their time and searching around. Each area should
          only take a few minutes (1-2), this can differ if players are more
          hesitant to move forward than others and if players aren’t looking for
          the pickups around the area. The gameplay is linear and will give
          subtle hints to the player to tell them where to go by using things
          such as lighting, SFX, purposeful line of sight etc.{" "}
        </p>
        <h2 className="pharm-h2">Gameplay</h2>
        <p id="paragraph-1">
          Players will be able to pick-up and read notes and hidden objects
          around the levels that will tell a story of how certain things have
          happened in the level.
          <br /> <br />
          There are different interactions the players can encounter inside the
          level and can range from using CCTV monitors, VHS tapes, and reading
          notes.
        </p>
        <h2 className="pharm-h2">My Responsibilities</h2>
        <p className="projects-bulleted-list">
          ⦁ Developed the game concept, genre, and narrative direction.
          <br />
          <br />
          ⦁ Designed the atmosphere, including level layouts and environmental
          storytelling
          <br />
          <br />
          ⦁ Implemented the game logic, mechanics, and user interaction within
          Unity
          <br />
          <br />
          ⦁ Sourced 3D models, textures, and animations for the environment and
          potential characters
          <br />
          <br />
          ⦁ Conducted research into the psychological horror genre and abandoned
          locations to create an authentic and unsettling experience.
          <br />
          <br />⦁ Managed the development timeline, budget, and documentation
          for the project
        </p>
        <div className="img-container">
          <Carousel
            infinite={true}
            responsive={responsive}
            className="owl-carousel owl-theme skill-slider"
          >
            <div className="item">
              <img src={pharm_1} alt="" />
            </div>
            <div className="item">
              <img src={pharm_2} alt="" />
            </div>
            <div className="item">
              <img src={pharm_3} alt="" />
            </div>
            <div className="item">
              <img src={pharm_4} alt="" />
            </div>
            <div className="item">
              <img src={pharm_5} alt="" />
            </div>
            <div className="item">
              <img src={pharm_6} alt="" />
            </div>
            <div className="item">
              <img src={pharm_7} alt="" />
            </div>
            <div className="item">
              <img src={pharm_8} alt="" />
            </div>
          </Carousel>
        </div>
      </section>
    </div>
  );
};

export default Pharm;
