import {Container, Row, Col, Tab, Nav} from "react-bootstrap";
import {ProjectCard} from "./ProjectCard";
import projImg1 from "../../assets/img/Portal_2.png";
import projImg2 from "../../assets/img/lotm_1.png";
import projImg3 from "../../assets/img/horror_game.png";
import "./Projects.css";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects = [
    {
      title: "Portal 2",
      description: "First Person Puzzle Game",
      imgUrl: projImg1,
      href: "/projects/portal",
      projButton: "port-button",
    },
    {
      title: "Legend of the Museum",
      description: "3D Top-Down RPG",
      imgUrl: projImg2,
      href: "/projects/lotm",
      projButton: "lotm-button",
    },
    {
      title: "Pharmystery",
      description: "First Person Horror",
      imgUrl: projImg3,
      href: "/projects/pharmystery",
      projButton: "phar-button",
    },
  ];

  return (
    <section className="home-project" id="home-projects">
      <h1 id="projects-title">Featured Work</h1>
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({isVisible}) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <p id="title-second-text"></p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    ></Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="section"></Tab.Pane>
                      <Tab.Pane eventKey="third"></Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
