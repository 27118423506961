import {Container, Row, Col} from "react-bootstrap";
import TrackVisibility from "react-on-screen";
import {ArrowRightCircle} from "react-bootstrap-icons";
import {Contact} from "../../components/Contact/Contact";
import {Projects} from "../../components/Home Projects/Projects";
import "animate.css";
import "./Home.css";

export const Home = () => {
  return (
    <div>
      <section className="banner" id="home">
        <Container>
          <Row className="aligh-items-center">
            <Col xs={12} md={6} xl={7}>
              <TrackVisibility>
                {({isVisible}) => (
                  <div
                    className={
                      isVisible ? "animate__animated animate__fadeIn" : ""
                    }
                  >
                    <h1>{`Connor Larkin`} </h1>
                    <h1 id="role-title">{"Level Designer"}</h1>
                    <p>
                      Recent Computer Games (Design) BA graduate specialising in
                      level design, proficient in Unity and Unreal Engine aiming
                      to create compelling and engaging gaming experiences to be
                      enjoyed by all.
                    </p>
                    <a href="#connect">
                      <button>
                        Let's Connect <ArrowRightCircle size={25} />
                      </button>
                    </a>
                  </div>
                )}
              </TrackVisibility>
            </Col>
            <Col xs={12} md={6} xl={5}>
              <TrackVisibility>
                {({isVisible}) => (
                  <div
                    className={
                      isVisible ? "animate__animated animate__zoomIn" : ""
                    }
                  ></div>
                )}
              </TrackVisibility>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="home-projects">
        <Container>
          <Projects />
        </Container>
      </section>
      <Contact href="#contact" />
    </div>
  );
};

export default Home;
