import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import {useEffect, useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import githubIcon from "../../assets/img/nav-github.svg";
import linkedinIcon from "../../assets/img/nav-linkedin.svg";
import "./NavBar.css";
import {useNavigate} from "react-router-dom";

function NavBar() {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState("home");
  // const [scrolled, setScrolled] = useState(false);

  function connectButton(url, targetHref) {
    if (!window.location.href.includes("home")) {
      navigate(url);
      setActiveLink("home");
      setTimeout(() => {
        const targetElement = document.querySelector(targetHref);
        if (targetElement) {
          targetElement.scrollIntoView({behaviour: "smooth"});
        } else {
          console.warn(`Element with href "${targetHref}" not found.`);
        }
      }, 500);
    } else {
      const targetElement = document.querySelector(targetHref);
      if (targetElement) {
        targetElement.scrollIntoView({behaviour: "smooth"});
      } else {
        console.warn(`Element with href "${targetHref}" not found.`);
      }
    }
  }

  // useEffect(() => {
  //   const onScroll = () => {
  //     if (window.scrollY > 50) {
  //       setScrolled(true);
  //     } else {
  //       setScrolled(false);
  //     }
  //   };

  //   window.addEventListener("scroll", onScroll);

  //   return () => window.removeEventListener("scroll", onScroll);
  // }, []);

  useEffect(() => {
    const handleActiveLink = () => {
      const currentPath = window.location.pathname;
      let newActiveLink;
      if (currentPath === "/") {
        newActiveLink = "home";
      } else if (currentPath.includes("about")) {
        newActiveLink = "about";
      } else if (currentPath.includes("projects")) {
        newActiveLink = "projects";
      } else {
        newActiveLink = "home";
      }
      setActiveLink(newActiveLink);
    };

    handleActiveLink();
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link
              href="/home"
              className={
                activeLink === "home" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("home")}
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="/about"
              className={
                activeLink === "about" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("about")}
            >
              About Me
            </Nav.Link>
            <NavDropdown
              title={<span>Projects</span>}
              id="nav-dropdown"
              className={
                activeLink === "projects"
                  ? "active nav-dropdown"
                  : "navbar-link"
              }
            >
              <NavDropdown.Item
                href="/projects/portal"
                onClick={() => onUpdateActiveLink("projects")}
              >
                Portal
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/projects/lotm"
                onClick={() => onUpdateActiveLink("projects")}
              >
                Legend of the Museum
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/projects/pharmystery"
                onClick={() => onUpdateActiveLink("projects")}
              >
                Pharmystery
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <span className="navbar-text ms-auto">
            <div className="social-icon">
              <a
                target="_blank"
                href="https://www.linkedin.com/in/connor-larkin-5839a42b7/"
                rel="noreferrer"
              >
                <img src={linkedinIcon} alt="linkedin" />
              </a>
              <a
                target="_blank"
                href="https://github.com/NaeMoney"
                rel="noreferrer"
              >
                <img src={githubIcon} alt="github" />
              </a>
            </div>
            {/* <HashLink to="home#connect"> */}
            <button
              className="vvd"
              onClick={() => connectButton("/home", "#connect")}
            >
              <span>Lets Connect</span>
            </button>
            {/* </HashLink> */}
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
