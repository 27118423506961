import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "./layout";
import Home from "./pages/Home/Home";
import LotM from "./pages/Project Pages/LotM";
import Pharm from "./pages/Project Pages/Pharm";
import Portal from "./pages/Project Pages/Portal";
import About from "./pages/About/About";

// const redirectHome = () => {
//   if (window.location.pathname === '/')
//   navigate("/home")
// }

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Navigate replace to="/home" />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects">
            <Route path="portal" element={<Portal />} />
            <Route path="pharmystery" element={<Pharm />} />
            <Route path="LotM" element={<LotM />} />
          </Route>
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
