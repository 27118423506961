import {useRef, useState} from "react";
import emailjs from "@emailjs/browser";
import {Container, Row, Col} from "react-bootstrap";
import contactImg from "../../assets/img/contact-img.svg";
import "animate.css";
import TrackVisibility from "react-on-screen";
import "./Contact.css";

export const Contact = () => {
  const form = useRef();
  const [message, setMessage] = useState("Message");
  const [messageClass, setMessageClass] = useState("peen");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("gmail", "resume_template", form.current, {
        publicKey: "qxxMNtlwYY9UtKR4d",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setMessageClass("success");
          console.log(messageClass);
          setMessage("Your Message has been sent!");
          setTimeout(() => {
            setMessage("Message");
            setMessageClass("px-1");
          }, 4000);
        },
        (error) => {
          console.log("FAILED...", error.text);
          setMessageClass("hello");
          setMessage("Error! Please try again later.");
          console.log(messageClass);
          setTimeout(() => {
            setMessage("Message");
            setMessageClass("px-1");
          }, 4000);
        }
      );
    e.target.reset();
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({isVisible}) => (
                <img
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                  src={contactImg}
                  alt="Contact Us"
                />
              )}
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({isVisible}) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Get In Touch</h2>
                  <form ref={form} onSubmit={sendEmail}>
                    <Row>
                      <Col size={12} sm={12} className="px-1">
                        <input
                          type="text"
                          name="user_name"
                          placeholder="Name"
                        />
                      </Col>
                      <Col size={12} sm={12} className="px-1">
                        <input
                          type="email"
                          name="user_email"
                          placeholder="Email Address"
                        />
                      </Col>
                      <Col size={12} className={messageClass}>
                        <textarea
                          rows="6"
                          name="message"
                          placeholder={message}
                        ></textarea>
                        <button type="submit">
                          <span>Send</span>
                        </button>
                      </Col>
                    </Row>
                  </form>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
