import React from "react";
import NavBar from "./components/NavBar/NavBar.js";
import { Footer } from "./components/Footer/Footer.js";

const Layout = ({ children }) => {
  return (
    <div>
      <NavBar />
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
